import { CosmosButton, CosmosCheckbox, CosmosIconDelete, CosmosIconPen, CosmosText } from "@cosmos/web/react"
import Loading from "../loading/loading"
import moment from "moment"

// Returns the markup and functionality for the guest rows
export default function GuestRow({
	id,
	name,
	group,
	email,
	checked_in,
	check_in_available,
	pin,
	toggleCheckIn,
	loading,
	redeemed,
	redeemedAt,
	deleteFromEvent,
	deleting,
	check_in_times,
	tour_name,
	tour_start,
	tour_guide,
	is_vcarb,
	editGuest
}) {
	return (
		<div
			className={["thosp-guest-row", checked_in ? "checked-in" : "", check_in_available ? "check-in-available" : ""].join(" ").trim()}
			data-guest-id={id}>
			<div className="guest-row-name">
				<CosmosText
					appearance="light"
					size="small">
					<strong>{name.trim()}</strong>, {group}
				</CosmosText>

				<CosmosText
					appearance="light"
					size="x-small"
					kind="subtle">
					{email}
				</CosmosText>

				{redeemed && (
					<CosmosText
						appearance="light"
						size="x-small">
						Gift redeemed, {moment(redeemedAt).format("dddd HH:mm:ss")}
					</CosmosText>
				)}
				{tour_start && (
					<CosmosText
						appearance="light"
						size="x-small">
						{tour_name} tour w/ {tour_guide}, {moment(tour_start).format("dddd HH:mm")}
					</CosmosText>
				)}

				{!redeemed && (checked_in || check_in_times != null) && (
					<CosmosText
						appearance="light"
						size="x-small">
						{pin}
					</CosmosText>
				)}
			</div>

			{check_in_available && (
				<div className="guest-row-checkin">
					<CosmosCheckbox
						name={id}
						appearance="light"
						checked={checked_in ? true : null}
						onInputchange={({ detail }) => toggleCheckIn(id, detail.checked, false, is_vcarb)}>
						{!loading && <>{checked_in ? "Checked in" : "Check in"}</>}
						{loading && (
							<Loading
								active={loading}
								label="Processing..."
							/>
						)}
					</CosmosCheckbox>
					<CosmosButton
						className="guest-row-edit"
						size="small"
						shape="square"
						kind="primary"
						onClick={editGuest}>
						<CosmosIconPen />		
					</CosmosButton>	
				</div>
			)}

			{!check_in_available && (
				<div className="guest-row-delete">
					<CosmosButton
						className="guest-row-edit"
						size="small"
						shape="square"
						kind="secondary"
						onClick={editGuest}>
						<CosmosIconPen />		
					</CosmosButton>	
					<CosmosButton
						size="small"
						shape="square"
						onClick={() => deleteFromEvent()}
						busy={deleting}>
						<CosmosIconDelete />
					</CosmosButton>
				</div>
			)}
		</div>
	)
}