import { useEffect, useState } from "react"
import moment from "moment"
import { CosmosButton, CosmosCheckbox, CosmosIconErrorFilled, CosmosInput, CosmosOption, CosmosSelect, CosmosText } from "@cosmos/web/react"

// Guest form component that can be used for both adding and editing guests
export default function GuestForm({ 
  guest, 
  eventDates, 
  eventGroups, 
  redemptionTypes,
  checkIn,
  setCheckIn,
  onSave, 
  onCancel,
  allGuests 
}) {
  // Form state
  const [firstName, setFirstName] = useState(guest?.first_name || "")
  const [lastName, setLastName] = useState(guest?.last_name || "")
  const [email, setEmail] = useState(guest?.email || "")
  const [group, setGroup] = useState(guest?.event_group_id || 0)
  const [dates, setDates] = useState(guest?.check_in_days ? guest.check_in_days.split(",") : [])
  const [additionalGift, setAdditionalGift] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errors, setErrors] = useState({})
  const [existingWarning, setExistingWarning] = useState(false)

  // When editing, we need to initialize dates properly
  useEffect(() => {
    if (guest?.check_in_days) {
      setDates(guest.check_in_days.split(","))
    }
  }, [guest])
  
  // Check for duplicate names
  const checkForExistingName = (firstName, lastName) => {
    if (firstName.trim() === '' || lastName.trim() === '') {
      return false
    }
    
    return allGuests.some((g) => {
      // If we're editing, exclude the current guest from the check
      if (guest && g.id === guest.id) {
        return false
      }
      return g.first_name === firstName && g.last_name === lastName
    })
  }
  
  const handleFirstNameChange = (firstName) => {
    setFirstName(firstName)
    setExistingWarning(checkForExistingName(firstName, lastName))
  }
  
  const handleLastNameChange = (lastName) => {
    setLastName(lastName)
    setExistingWarning(checkForExistingName(firstName, lastName))
  }
  
  const handleSubmit = () => {
    setIsSubmitting(true)
    let formErrors = {}
    
    if (!firstName) {
      formErrors["firstName"] = "First name cannot be empty"
    }
    
    if (!lastName) {
      formErrors["lastName"] = "Last name cannot be empty"
    }
    
    if (!group || group < 1) {
      formErrors["group"] = "Group cannot be empty"
    }
    
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors)
      setIsSubmitting(false)
      return
    }
    
    if (dates.length === 0) {
      setIsSubmitting(false)
      return
    }
    
    // Join the dates array into a string
    const daysAttending = [...dates].sort((a, b) => a - b).join(",")
    
    // Call the onSave function with the form data
    onSave({
      id: guest?.id,
      firstName,
      lastName,
      email,
      daysAttending,
      group,
      additionalGift
    })
      .then(() => {
        setIsSubmitting(false)
      })
      .catch(() => {
        setIsSubmitting(false)
      })
  }

  return (
    <div className="thosp-add-guest-form">
      <CosmosInput
        appearance="light"
        label="First name:"
        disabled={guest}
        invalid={errors.firstName ? true : null}
        validationMessage={errors.firstName}
        value={firstName}
        onInputinput={({ detail }) => handleFirstNameChange(detail.value)}
      />
      
      <CosmosInput
        appearance="light"
        label="Last name:"
        disabled={guest}
        invalid={errors.lastName ? true : null}
        validationMessage={errors.lastName}
        value={lastName}
        onInputinput={({ detail }) => handleLastNameChange(detail.value)}
      />
      
     <CosmosInput
        appearance="light"
        label="Email:"
        className="span-2"
        value={email}
        disabled={guest}
        onInputinput={({ detail }) => setEmail(detail.value)}
      />
      
      <CosmosSelect
        appearance="light"
        label="Group:"
        className="span-2"
        invalid={errors.group ? true : null}
        validationMessage={errors.group}
        value={group}
        onInputchange={({ detail }) => setGroup(detail.value)}>
        {eventGroups?.map((group) => (
          <CosmosOption key={group.id} value={group.id}>
            {group.name} {group.is_vcarb === true ? '(VCARB)' : '(ORBR)'}
          </CosmosOption>
        ))}
      </CosmosSelect>
      
      <div className="span-2 add-guest-dates">
        {Object.entries(eventDates).map(([key, date]) => (
          <CosmosCheckbox
            key={key}
            appearance="light"
            checked={dates.includes(key)}
            onInputchange={({ detail }) => {
              if (detail.checked) {
                setDates((currentDates) => [...currentDates, key])
              } else {
                const filteredDates = dates.filter((d) => d !== key)
                setDates(filteredDates)
              }
            }}>
            {date}
          </CosmosCheckbox>
        ))}
      </div>
      
      {!guest && (
        <>
          <div className="span-2 check-guest-in">
            <CosmosCheckbox
              appearance="light"
              checked={checkIn}
              onInputchange={({ detail }) => setCheckIn(detail.checked)}>
              Check in now
            </CosmosCheckbox>
            
            <CosmosText
              appearance="light"
              size="x-small"
              kind="subtle">
              They will be checked in for {moment().format("DD/MM/YYYY")}
            </CosmosText>
          </div>
          
          {redemptionTypes.length > 0 && (
            <div className="span-2 add-guest-gift">
              <CosmosCheckbox 
                appearance="light"
                disabled={!checkIn}
                checked={additionalGift}
                onInputchange={({ detail }) => setAdditionalGift(detail.checked)}>
                {redemptionTypes.map((gift) => gift.description)} gift received
              </CosmosCheckbox>
              
              <CosmosText
                appearance="light"
                size="x-small"
                kind="subtle">
                Can only collect their gift if checking in now
              </CosmosText>
            </div>
          )}
        </>
      )}
      
      {existingWarning && (
        <CosmosText className="span-2 warningIcon" appearance="light" size="small">
          <CosmosIconErrorFilled className="warningIcon" /> A guest with the same name already exists!
        </CosmosText>
      )}
      
      <div className="span-2 add-guest-buttons">
        <CosmosButton
          appearance="light"
          shape="rectangle"
          size="small"
          kind="secondary"
          onClick={onCancel}>
          Cancel
        </CosmosButton>
        
        <CosmosButton
          appearance="light"
          shape="rectangle"
          size="small"
          busy={isSubmitting}
          onClick={handleSubmit}>
          {guest ? 'Save Changes' : 'Add Guest'}
        </CosmosButton>
      </div>
    </div>
  )
}