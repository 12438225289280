import { useEffect, useState, useRef, createContext } from "react"
import { useIsAuthenticated } from "@azure/msal-react"
import { useMsal } from "@azure/msal-react"

// Import the msal config
import { msalConfig } from "../authConfig"

// Create a new global context
export const AppContext = createContext()

// Export the children, wrapped in the app context
export const AppProvider = ({ children }) => {
	const [user, setUser] = useState({
		isLoggedIn: false,
		name: "",
	})

	// Define the scope of the app
	const [scope, setScope] = useState("")

	// Server status and pending requests
	const [network, setNetwork] = useState({
		online: true,
		pendingRequests: 0,
		manualToggle: false,
	})

	// App version
	const appVersion = "0.9.6"

	// Get the current app version from the local storage
	const currentAppVersion = localStorage.getItem("RBR_Trackside_Version") || null

	// If the app version, and the one in the local storage don't match
	if (currentAppVersion !== appVersion) {
		// Clear the local storage
		localStorage.clear()

		// And set the new version into the local storage
		localStorage.setItem("RBR_Trackside_Version", appVersion)
	}

	// Keep a reference of the pending requests while offline
	const pendingRequests = useRef(0)

	// Pull the authenticated boolean from the msal instance
	const isAuthenticated = useIsAuthenticated()

	// Pull the accounts in the localStorage instance of the Msal
	const { accounts, instance, inProgress } = useMsal()

	// Aquire a new auth token with the MSAL pacakge
	const acquireMSALToken = async () => {
		try {
			// Pull the clientId from the config
			const { clientId } = msalConfig?.auth

			// Wait for the MSAL instance to be ready
			while (inProgress !== "none" || accounts?.length === 0 || instance === null) {
				await new Promise((resolve) => setTimeout(resolve, 100))
			}

			// Make the request into the MSAL instance for the token
			const response = await instance.acquireTokenSilent({
				scopes: [`api://${clientId}/rbr.tracksidehospitality.signin`],
				account: accounts[0],
			})

			// Pull the access token from the response
			const { accessToken } = response

			// Return this from the function
			return accessToken
		} catch (error) {
			// Handle errors
			console.log("Error getting MSAL token:", error)

			// And return null
			return null
		}
	}

	// On context mount
	useEffect(() => {
		// Get the hostname of the current request
		const currentHost = window.location.hostname

		// Production domains for both apps, used to set the scope
		const ORBR_Domains = ["trackside-hospitality.redbull.racing", "www.trackside-hospitality.redbull.racing"]
		const VCARB_Domains = ["trackside-hospitality-vcarb.herokuapp.com", "www.trackside-hospitality-vcarb.herokuapp.com"]

		// Check the environment we're currently running in
		if (ORBR_Domains.includes(currentHost)) {
			setScope("ORBR")
		} else if (VCARB_Domains.includes(currentHost)) {
			setScope("VCARB")
		} else {
			// Local & staging domains fallback
			setScope("ORBR")
		}
	}, [])

	// When the authentication is updated
	useEffect(() => {
		// Update the auth object in the state with user details/tokens
		const updateStateAuth = async () => {
			if (!isAuthenticated) {
				// Reset the user state object
				setUser({
					isLoggedIn: false,
					name: "",
				})
			} else {
				// Get the offline toggle from the local storage
				const offlineToggle = localStorage.getItem("rbr_trackside_offline_toggle") || false

				// Get the offline toggle as a boolean
				const isOfflineToggled = offlineToggle === "true" ? true : false

				// Check to make sure we are online
				if (!network.online || isOfflineToggled) {
					// Set the user details into the state
					setUser({
						isLoggedIn: true,
						name: accounts[0].name,
					})
				}

				// Generate a new token
				const msalToken = await acquireMSALToken()

				// If there was no token returned, don't continue
				if (!msalToken) {
					// Wait for 200ms before trying again
					setTimeout(() => {
						updateStateAuth()
					}, 500)
				}

				// Set the token into the local storage
				localStorage.setItem("RBR_Trackside_MSAL_Auth", msalToken)

				// Then set the user details into the state
				setUser({
					isLoggedIn: true,
					name: accounts[0].name,
				})
			}
		}

		// Get the pathname of the current page
		const currentPath = window.location.pathname

		// If we are not on the event-registration page
		if (currentPath !== "/event-registration") {
			// Update the auth state
			updateStateAuth()
		}
	}, [isAuthenticated, inProgress, accounts, instance])

	// On context mount
	useEffect(() => {
		// Get the offline toggle from the local storage
		const offlineToggle = localStorage.getItem("rbr_trackside_offline_toggle") || false

		// Get the offline toggle as a boolean
		const isOfflineToggled = offlineToggle === "true" ? true : false

		// Fetch the pending requests
		const offlineRequests = JSON.parse(localStorage.getItem("rbr_trackside_requests")) || []

		// Is the toggle set to offline?
		if (isOfflineToggled) {
			setNetwork((network) => ({
				...network,
				online: false,
				manualToggle: true,
				pendingRequests: offlineRequests.length,
			}))
		} else {
			// Otherwise set the state as usual
			setNetwork((network) => ({
				...network,
				online: navigator.onLine,
				manualToggle: false,
				pendingRequests: offlineRequests.length,
			}))
		}

		// Then add some event listeners for network changes
		window.addEventListener("online", function () {
			if (!network.manualToggle) return

			setNetwork((network) => ({ ...network, online: true }))
		})

		window.addEventListener("offline", function () {
			if (!network.manualToggle) return

			setNetwork((network) => ({ ...network, online: false }))
		})

		// Then remove the listeners on context unmount
		return () => {
			window.removeEventListener("online", null)
			window.removeEventListener("offline", null)
		}
	}, [])

	// When the network object changes
	useEffect(() => {
		// If the network get set to offline
		if (!network.online) {
			// Start an interval to fetch the pending requests
			const requestCheck = setInterval(() => {
				// If the network is back online, stop processing
				if (network.online) {
					clearInterval(requestCheck)
					return
				}

				// Fetch the pending requests
				const offlineRequests = JSON.parse(localStorage.getItem("rbr_trackside_requests")) || []

				// Set the total count into the state
				if (pendingRequests.current !== offlineRequests.length) {
					// Set it locally to avoid async waits
					pendingRequests.current = offlineRequests.length

					// Then set the figure into the state
					setNetwork((network) => ({ ...network, pendingRequests: offlineRequests.length }))
				}
			}, 2000)
		}
	}, [network])

	// Process the logout request by clearing local storage and app context
	const processLogout = async () => {
		// Clear the local storage
		localStorage.clear()

		// Reset the user state object
		setUser({ isLoggedIn: false })
	}

	// Toggle the manual flag on the network object
	const toggleManualOffline = () => {
		// Are we going to be online?
		if (!network.manualToggle) {
			// Set the online status to the navigator status
			setNetwork((network) => ({ ...network, online: false, manualToggle: true }))

			// Set the toggle value into the local storage
			localStorage.setItem("rbr_trackside_offline_toggle", true)
		} else {
			// Set the online status to the navigator status
			setNetwork((network) => ({ ...network, online: navigator.onLine, manualToggle: false }))

			// Set the toggle value into the local storage
			localStorage.setItem("rbr_trackside_offline_toggle", false)
		}
	}

	return <AppContext.Provider value={{ appVersion, user, processLogout, network, setNetwork, toggleManualOffline, scope }}>{children}</AppContext.Provider>
}
