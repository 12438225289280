import { useContext, useEffect, useState } from "react"
import moment from "moment"
import { AppContext } from "../../../utils/context"

// Utility function
import { fetchCSVExport, safeDataFetch, safeRequest, redeemGuestPinWithType } from "../../../utils/database"

// UI components
import { CosmosButton, CosmosCheckbox, CosmosIconAuthor, CosmosIconClose, CosmosIconDownload, CosmosInput, CosmosSpinner, CosmosText, CosmosTitle } from "@cosmos/web/react"
import RBRLogo from "../../../assets/images/red_bull_racing_CROPPED.png"
import VCarbLogo from "../../../assets/images/vcarb-logo.png"
import Loading from "../../../components/loading/loading"
import CountryFlag from "../../../components/flag/flag"
import GuestRow from "../../../components/guest/row"
import RBRCosmosSelect from "../../../components/select/select"
import GuestForm from "../../../components/guest/form" // Import the GuestForm component

import { eventRedemptions } from "../../../utils/data/additionalRedemptions"

// Returns the markup for viewing the event guest list
export default function Guests({ country, location, countryCode, eventID, eventName, eventDates }) {
	const [loading, setLoading] = useState(true)
	const [input, setInput] = useState("")
	const [inputDate, setInputDate] = useState({})
	const [guests, setGuests] = useState([])
	const [guestCount, setGuestCount] = useState(0)
	const [filteredGuests, setFilteredGuests] = useState([])
	const [checkedInGuests, setCheckedInGuests] = useState(0)
	const [processingID, setProcessingID] = useState(null)
	const [viewingPin, setViewingPin] = useState({})
	const [addingGuest, setAddingGuest] = useState(false)
	const [editingGuest, setEditingGuest] = useState(null) 
	const [exporting, setExporting] = useState(false)
	const [tours, setTours] = useState([])
	const [tourGuestID, setTourGuestID] = useState(null)
	const [joinTourID, setJoinTourID] = useState(null)
	const [joiningTour, setJoiningTour] = useState(false)
	const [withoutGiftBag, setWithoutGiftBag] = useState(0)
	const [groups, setGroups] = useState([])
	const [eventGroups, setEventGroups] = useState([])    
	const [isVcarb, setIsVcarb] = useState(false)
	const [usePin, setUsePin] = useState(false)
	const [checkIn, setCheckIn] = useState(false)

	// State used for when deleting guest
	const [deletingGuest, setDeletingGuest] = useState(null)
	const [tourErrors, setTourErrors] = useState(null)

	const [guestListUpdated, setGuestListUpdated] = useState(false)

	const { user } = useContext(AppContext)

	// Different redemption types
	const redemptionTypes = [
		...(eventRedemptions[eventID] || []),
	]

	// On component mount
	useEffect(() => {
		fetchEventGuestList()
	}, [])

	// Add an effect to monitor state updates
	useEffect(() => {
		if (guestListUpdated) {
			setGuestListUpdated(false) // Reset the flag
		}
	}, [guests, guestListUpdated])

	// On input change
	useEffect(() => {
		filterGuestResults()
	}, [guests, input, inputDate])

	// When the search by date field is updated
	useEffect(() => {
		// Is there any option available in the state?
		if (inputDate.option) {
			// Setup a new array of all guests that match the given check in date
			const guestsByDate = [...guests].filter((guest) => {
				// Get the check_in_days field from the guest
				const checkInDates = guest.check_in_days || ""

				// Return any matches that also fall inline with the check in date chosen
				return checkInDates.includes(inputDate.option)
			})

			// Set the length of this new array into the state
			setGuestCount(guestsByDate.length)
		} else {
			// Otherwise set it to the length of the full guest list
			setGuestCount(guests.length)
		}
	}, [guests, inputDate])

	// When the guests are updated
	useEffect(() => {
		// Is there any option available in the state?
		if (inputDate.option) {
			// Setup a new array of all guests that match the given check in date
			const guestsCheckedInByDate = [...guests].filter((guest) => {
				// Get the check_in_days field from the guest
				const checkInDates = guest.check_in_days || ""
				const checkedInTimestamps = guest.check_in_times || ""

				// Return any matches that also fall inline with the check in date chosen
				return checkInDates.includes(inputDate.option) && checkedInTimestamps.includes(inputDate.option)
			})

			// Set the length of this new array into the state
			setCheckedInGuests(guestsCheckedInByDate?.length || 0)
		} else {
			// Find all the guests that are checked in
			const checkedInGuests = guests.filter((guest) => guest.check_in?.length > 0)

			// Were there any found?
			if (checkedInGuests && checkedInGuests.length > 0) {
				// Set the length of this new array into the state
				setCheckedInGuests(checkedInGuests.length)
			} else {
				// Otherwise just reset the count back to 0
				setCheckedInGuests(0)
			}
		}
	}, [guests, inputDate])

	useEffect(() => {
        // Fetch an updated list of the groups
        const fetchAllGroups = async () => {
            // Fetch the groups list
            const groupsList = await safeDataFetch('GET', '/groups', {}, 'GROUPS')

            // Setup a new object 
            let groupsObj = {}

            // Loop over the groups on the response and write them into the object
            groupsList.forEach((group) => {
                groupsObj[group.id] = group.name + ' (' + (group.is_vcarb === true ? 'VCARB': 'ORBR') + ')'
            })

            // Set it into the state
            setGroups(groupsObj)

            // And then fetch the event groups
            fetchEventGroups()
        }

        // Fetch the entire list of available groups
        fetchAllGroups()
    }, [])

	// Fetch the guest list for a given event ID
	const fetchEventGuestList = async () => {
		// Pull a list of the event guests
		const eventGuests = await safeDataFetch("GET", `/guest_list/${eventID}`, {}, `GUEST_LIST_${eventID}`)

		return new Promise((resolve) => {
			// Set them into the state
			if (eventGuests.length > 0) {
				setGuests(eventGuests)
				setFilteredGuests(eventGuests)

				// Get a count for the number of guests where gift_recieved is false
				const noGiftBagYet = eventGuests.filter((guest) => {
					return !guest.gift_received || guest.gift_received === false
				})?.length

				// Set this into the state
				setWithoutGiftBag(noGiftBagYet)
			} else {
				setGuests([])
				setFilteredGuests([])
			}

			// Reset the loading state
			setLoading(false)
			setDeletingGuest(null)
			resolve()
		})
	}

	// Fetch the groups for the given event from the database
	const fetchEventGroups = async () => {
		// Fetch the groups for the given event
		const eventGroupsFetch = await safeDataFetch('GET', `/event/${eventID}/groups`, {}, `EVENT_${eventID}_GROUPS`)

		// Set them into the state
		if (eventGroupsFetch.length > 0) {
			const sortedItems = eventGroupsFetch.slice().sort((a, b) => a.name.localeCompare(b.name))
			setEventGroups(sortedItems)
		}

		// Reset the loading state
		setLoading(false)
	}

	// Filter through the guest results
	const filterGuestResults = () => {
		// If there is no user input
		if ((!input || input.length === 0) && (!inputDate.option || inputDate.length === 0)) {
			// Set the filtered list to the original
			setFilteredGuests(guests)

			// Set the global gift bags
			setWithoutGiftBag(
				guests.filter((guest) => {
					return !guest.gift_received || guest.gift_received === false
				})?.length
			)

			// Stop processing
			return
		}

		// Otherwise filter where either the name or email contain the search input
		const filtered = [...guests].filter((guest) => {
			// Lowercase all the inputs
			const gName = `${guest.first_name?.toLowerCase() || ""} ${guest.last_name?.toLowerCase() || ""}`
			const gEmail = guest.email?.toLowerCase() || ""
			const gGroup = guest.group_name?.toLowerCase() || ""
			const checkInDates = guest.check_in_days || ""

			// Lowercase the user input
			const inputLower = input.toLowerCase()

			// If we have a check in date chosen
			if (inputDate?.option) {
				// Return any matches that also fall inline with the check in date chosen
				return (gName.includes(inputLower) || gEmail.includes(inputLower) || gGroup.includes(inputLower)) && checkInDates.includes(inputDate.option)
			} else {
				// Otherwise just search for the name/group matches
				return gName.includes(inputLower) || gEmail.includes(inputLower) || gGroup.includes(inputLower)
			}
		})

		// Set the global gift bags
		setWithoutGiftBag(
			filtered.filter((guest) => {
				return !guest.gift_received || guest.gift_received === false
			})?.length
		)

		// Then set that into the state
		setFilteredGuests(filtered)
	}

	// Toggle the checkIn state of the given guest
	const toggleUserCheckIn = async (guestID, currentStatus, isNow = false, is_vcarb) => {
		// Set the guestID into the state for a loading status
		setProcessingID(guestID)
		setTourGuestID(guestID)		
		setIsVcarb(is_vcarb)

		// Reset the tour join state
		setJoinTourID(null)

		// Get the local date & time to the device
		let checkInTimestamp = ""

		// Are we checking the user in now?
		if (isNow) {
			// Get tomorrow formatted
			checkInTimestamp = moment().add(1, 'days').format("DD/MM/YYYY HH:mm:ss")

		} else {
			checkInTimestamp = `${inputDate.option} ${moment().format("HH:mm:ss")}`
		}

		// Make the request to the relevant endpoint
		if (currentStatus) {
			const guest = guests.find(a => a.id === guestID)
			setUsePin(true)			
			if (guest?.check_in_times !== null && guest?.check_in_times !== undefined && guest?.check_in_times !== "" && guest?.check_in_times.length > 5)
			{	
				setUsePin(false)
			}			

			// Make the request
			const checkinResponse = await safeRequest("POST", `/guests/checkin/${guestID}`, { timestamp: checkInTimestamp })

			// If the request didn't fail
			if (checkinResponse?.status !== 400 && checkinResponse?.name !== "CheckInFailed" && typeof checkinResponse !== "undefined" && !checkinResponse?.offline) {
				// Get the pin (redemption code) from the response
				const { pin, name, guestgroup, check_in_string } = checkinResponse

				// Are they collecting the additional gift now?
				if (additionalGift) {
					// Run a redemption check locally
					const giftType = redemptionTypes.map((gift) => gift.code)
					const redemptionResult = await redeemGuestPinWithType(eventID, pin, giftType[0], user.name)

					const currentTypesRedeemed = localStorage.getItem(`rbr_trackside_REDEEMED_TYPES_${pin}`) || {}
					localStorage.setItem(`rbr_trackside_REDEEMED_TYPES_${pin}`, JSON.stringify({ redemptions: { ...currentTypesRedeemed, [giftType[0]]: new Date().toISOString() } }))

					// If there were no records found against the given pin code
					if (redemptionResult.status === 404) {
						// Update the state
						// setPinError("That pin was not found against any guests")
						console.log('error redeeming gift')
					}
				}

				// Pull a list of the tours
				const eventTours = await safeDataFetch("GET", `/event/${eventID}/tours`, {}, `EVENT_TOURS_${eventID}`)

				// Set them into the state
				if (eventTours.length > 0) {
					setTours(eventTours)
				} else {
					setTours([])
				}

				// Set this pin into the state to view
				setViewingPin({ pin, name, guestgroup, check_in_string, is_vcarb })
			} else if (checkinResponse?.offline) {
				// If the request was deemed to have been made offline, set the pin locally
				const localGuestsCache = JSON.parse(localStorage.getItem(`rbr_trackside_GUEST_LIST_${eventID}`)) || {}

				// Find the guest record in the array with the guestID and update the check in time
				const guestRecordIndex = localGuestsCache.findIndex((guest) => guest.id === guestID)

				// Parse some of the data from the guests record
				const { pin, first_name, last_name, group_name, check_in_days, check_in_times } = localGuestsCache[guestRecordIndex]

				// The check_in_days should be a comma seperated list of dates the guest is attending
				const daysAttending = check_in_days?.split(",") || []

				// Get a UTC timestamp for the check in time
				const checkInDay = moment(checkInTimestamp, "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY")

				// Loop over the days attending with todays date to find the array index
				const dayIndex = daysAttending.findIndex((day) => day === checkInDay)

				// Now split the check_in_times in a similar method
				let checkInTimes = check_in_times?.split(",") || []

				// Let's first check to make sure the check in times and dates array lengths match
				if (daysAttending.length !== checkInTimes.length) {
					// Are the check in times empty?
					if (checkInTimes.length === 0) {
						checkInTimes = Array(daysAttending.length).fill(null)
					}
				}

				// Then write the provided timestamp into the index for the given day
				checkInTimes[dayIndex] = checkInTimestamp

				// Join this array back together as a comma seperated list
				const checkInString = checkInTimes.join(",")

				// Update the check in time
				localGuestsCache[guestRecordIndex].check_in_times = checkInString

				// Update the local storage
				localStorage.setItem(`rbr_trackside_GUEST_LIST_${eventID}`, JSON.stringify(localGuestsCache))

				// Set this pin into the state to view
				setViewingPin({ pin, name: `${first_name} ${last_name}`, guestgroup: group_name, check_in_string: checkInTimestamp, is_vcarb })
			}
			else {
				console.log('an error occurred')
			}
		} else {
			// Attempt to make the request through the network
			const checkOutResponse = await safeRequest("POST", `/guests/reverse_checkin/${guestID}`, { date: inputDate.option })

			// If the response came back as offline
			if (checkOutResponse.offline) {
				// If the request was deemed to have been made offline, set the pin locally
				const localGuestsCache = JSON.parse(localStorage.getItem(`rbr_trackside_GUEST_LIST_${eventID}`)) || {}

				// Find the guest record in the array with the guestID and update the check in time
				const guestRecordIndex = localGuestsCache.findIndex((guest) => guest.id === guestID)

				// Parse some of the data from the guests record
				const { check_in_days, check_in_times } = localGuestsCache[guestRecordIndex]

				// The check_in_days should be a comma seperated list of dates the guest is attending
				const daysAttending = check_in_days?.split(",") || []

				// Get a UTC timestamp for the check in time
				const checkInDay = moment(checkInTimestamp, "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY")

				// Loop over the days attending with todays date to find the array index
				const dayIndex = daysAttending.findIndex((day) => day === checkInDay)

				// Now split the check_in_times in a similar method
				let checkInTimes = check_in_times?.split(",") || []

				// Let's first check to make sure the check in times and dates array lengths match
				if (daysAttending.length !== checkInTimes.length) {
					// Are the check in times empty?
					if (checkInTimes.length === 0) {
						checkInTimes = Array(daysAttending.length).fill(null)
					}
				}

				// Then write the provided timestamp into the index for the given day
				checkInTimes[dayIndex] = ""

				// Join this array back together as a comma seperated list
				const checkInString = checkInTimes.join(",")

				// Update the check in time
				localGuestsCache[guestRecordIndex].check_in_times = checkInString

				// Update the local storage
				localStorage.setItem(`rbr_trackside_GUEST_LIST_${eventID}`, JSON.stringify(localGuestsCache))
			}
		}

		// Reset the state and fetch an updated list
		fetchEventGuestList()
		setProcessingID(null)
	}

	// When the input field is updated from the search filter
	const handleInputChange = (value) => {
		// Set it into the state
		setInput(value)
	}

	// When a day has been chosen from the filter dropdown
	const handleDayFilter = (date) => {
		// Set the date into the state
		setInputDate(date)
	}

	// Add the guest to the event as an individual
	const addNewGuest = async (formData) => {
		// Join the dates array into a string if it's not already a string
		const daysAttending = typeof formData.daysAttending === 'string' 
			? formData.daysAttending 
			: [...formData.daysAttending].sort((a, b) => a - b).join(",")

		// Make the request
		const addGuestResult = await safeRequest("POST", "/guests", { 
			eventID, 
			firstName: formData.firstName, 
			lastName: formData.lastName, 
			email: formData.email, 
			daysAttending, 
			group: formData.group 
		})

		// Was there an ID returned from the request?
		if (addGuestResult[0]?.id) {

			const newGuestId = addGuestResult[0]?.id
			
			// Re-fetch the event guest list
			await fetchEventGuestList()

			// Do we want to check the user in right away (from the add form)
			if (checkIn) {
				toggleUserCheckIn(newGuestId, true, true, formData.group.is_vcarb)
			}
			
			return true
		} else if (addGuestResult.offline) {
			console.log("Request was made offline")
			return true
		} else {
			// @TODO Set a global error here to show under the form
			return false
		}
	}

	// Update an existing guest
	const updateGuest = async (formData) => {
		// Make sure we have an ID
		if (!formData.id) {
			return false
		}

		// Join the dates array into a string if it's not already a string
		const daysAttending = typeof formData.daysAttending === 'string' 
			? formData.daysAttending 
			: [...formData.daysAttending].sort((a, b) => a - b).join(",")

		// Make the request
		const updateGuestResult = await safeRequest("PUT", `/guests/${formData.id}`, { 
			check_in_days: daysAttending, 
			event_group_id: formData.group
		})
		
		// Was the update successful?
		if (updateGuestResult) {
			// Re-fetch the event guest list
			await fetchEventGuestList()
			return true
		} else {
			// @TODO Set a global error here to show under the form
			return false
		}
	}

	// Handle saving guest (add or update)
	const handleSaveGuest = async (formData) => {
		if (formData.id) {
			// Update existing guest
			const result = await updateGuest(formData)
			if (result) {
				setEditingGuest(null)
			}
			return result
		} else {
			// Add new guest
			const result = await addNewGuest(formData)
			if (result) {
				resetAddingGuestState()
			}
			return result
		}
	}

	// Reset the state when adding a new guest is cancelled
	const resetAddingGuestState = () => {
		// Reset the inputs and form state
		setCheckIn(false)
		setAddingGuest(false)
	}

	// Handle edit guest request
	const handleEditGuest = (guest) => {
		setEditingGuest(guest)
	}

	// Cancel editing guest
	const cancelEditGuest = () => {
		setEditingGuest(null)
	}

	// Delete a specific guest from the event
	const deleteGuestFromEvent = async (guestID) => {
		setDeletingGuest(guestID)

		// Make the request
		const deleteGuestResponse = await safeRequest("DELETE", `/guests/${guestID}`)

		// Was the request made offline?
		if (deleteGuestResponse?.offline) {
			// Get the local guest list
			const localGuestsCache = JSON.parse(localStorage.getItem(`rbr_trackside_GUEST_LIST_${eventID}`)) || {}

			// Find the guest record in the array with the guestID
			const guestRecordIndex = localGuestsCache.findIndex((guest) => guest.id === guestID)

			// Use this index to delete the record from the array
			localGuestsCache.splice(guestRecordIndex, 1)

			// Update the local storage
			localStorage.setItem(`rbr_trackside_GUEST_LIST_${eventID}`, JSON.stringify(localGuestsCache))
		}

		// Then finally refetch the guest list
		fetchEventGuestList()
	}
	
	// Find a guest by ID
	const findGuestById = (guestId) => {
		return guests.find(guest => guest.id === guestId)
	}

	// Clear the filtering for the guest list
	const resetFiltering = () => {
		setInput("")
		setInputDate({})
	}

	// Export the guest list to a CSV
	const exportGuestList = async () => {
		// Show the loading spinner
		setExporting(true)

		// Store a string for the date to pass into the request
		let dateKey = ""

		// Do we have a date available in the state?
		if (inputDate.option && typeof inputDate.option !== "undefined") {
			dateKey = inputDate.option.replaceAll("/", "-")
		} else {
			dateKey = "all"
		}

		// Make the request
		await fetchCSVExport("GET", `/guest_list/export/${eventID}/${dateKey}`, `${eventName.replaceAll(" ", "_")}_Guest_List_${dateKey.toUpperCase()}`)

		// Reset the loading spinner
		setExporting(false)
	}

	// Process tour join and return to checkin
	const processTourJoin = async () => {
		// Was there a tour chosen?
		if (joinTourID && joinTourID?.length > 0) {
			const tourAdd = await safeRequest("POST", `/tour/guest/${joinTourID}`, { guestID: tourGuestID })
			if (tourAdd?.result) {
				// Then reset the state
				setJoinTourID(null)
				setTourGuestID(null)
				setViewingPin({})
			} else {
				// Otherwise show an error message
				setTourErrors("Cannot add guest to tour, tour is full")
			}
		} else {
			// Then reset the state
			setJoinTourID(null)
			setTourGuestID(null)
			setViewingPin({})
		}
		fetchEventGuestList()
	}

	return (
		<>
			<div className="thosp-header-button">
				<CosmosTitle
					appearance="light"
					size="x-small"
					spacing="none">
					{addingGuest ? "Adding Guest" : editingGuest ? "Editing Guest" : "Guest List"}
					<CosmosText
						appearance="light"
						size="x-small"
						spacing="long-form-bottom">
						{inputDate.option ? guestCount : guests?.length} Gift Bags ({withoutGiftBag} Remaining)
					</CosmosText>
				</CosmosTitle>

				{!addingGuest && !editingGuest && (
					<CosmosButton
						shape="square"
						size="small"
						onClick={() => setAddingGuest(!addingGuest)}>
						<CosmosIconAuthor />
					</CosmosButton>
				)}
			</div>

			<Loading
				active={loading}
				label="Fetching guest list..."
			/>

			{guests.length === 0 && !loading && !addingGuest && !editingGuest && (
				<CosmosText
					appearance="light"
					size="small"
					kind="subtle">
					Guest list is currently empty
				</CosmosText>
			)}

			{/* Adding a new guest */}
			{addingGuest && (
				<GuestForm
					eventDates={eventDates}
					eventGroups={eventGroups}
					redemptionTypes={redemptionTypes}
					checkIn={checkIn}
					setCheckIn={setCheckIn}
					onSave={handleSaveGuest}
					onCancel={resetAddingGuestState}
					allGuests={guests}
				/>
			)}
			
			{/* Editing an existing guest */}
			{editingGuest && (
				<GuestForm
					guest={editingGuest}
					eventDates={eventDates}
					eventGroups={eventGroups}
					redemptionTypes={redemptionTypes}
					checkIn={checkIn}
					setCheckIn={setCheckIn}
					onSave={handleSaveGuest}
					onCancel={cancelEditGuest}
					allGuests={guests}
				/>
			)}

			{guests.length > 0 && !addingGuest && !editingGuest && (
				<>
					{inputDate.option && !loading && (
						<div className={["thosp-checkin-count", checkedInGuests === guests.length ? "is-full" : "is-not-full"].join(" ").trim()}>
							<CosmosText
								appearance="light"
								size="x-small"
								spacing="none"
								kind="subtle">
								{checkedInGuests === guests.length && <>All guests are checked in</>}

								{checkedInGuests !== guests.length && (
									<>
										<strong>
											{checkedInGuests}/{guestCount}
										</strong>{" "}
										guests checked in
									</>
								)}
							</CosmosText>
						</div>
					)}

					<br />

					<div className="thosp-guest-list-filtering">
						<CosmosInput
							appearance="light"
							label="Search by name:"
							clearable={true}
							onInputchange={({ detail }) => handleInputChange(detail.value)}
							onInputinput={({ detail }) => handleInputChange(detail.value)}
						/>

						<RBRCosmosSelect
							label="Search by day:"
							selected={inputDate.option}
							options={eventDates}
							onSelect={(date) => handleDayFilter(date)}
						/>

						<CosmosButton
							size="small"
							shape="square"
							appearance="light"
							kind="secondary"
							onClick={() => resetFiltering()}>
							<CosmosIconClose />
						</CosmosButton>

						<CosmosButton
							size="small"
							shape="square"
							appearance="light"
							kind="secondary"
							onClick={() => exportGuestList()}>
							{exporting ? <CosmosSpinner appearance="light" /> : <CosmosIconDownload />}
						</CosmosButton>
					</div>

					<br />

					{(input.length > 0 || inputDate.value) && (
						<CosmosText
							appearance="light"
							size="x-small"
							kind="subtle"
							spacing="long-form-bottom">
							Filtering for: {input} {input ? `on ${inputDate.value}` : inputDate.value}
						</CosmosText>
					)}

					{filteredGuests.length === 0 && !loading && (
						<CosmosText
							appearance="light"
							size="small">
							No guests found with those filters
						</CosmosText>
					)}

					{filteredGuests.map((guest) => (
						<GuestRow
							id={guest.id}
							key={guest.id}
							name={`${guest.first_name} ${guest.last_name || ""}`}
							group={guest.group_name}
							redeemed={guest.gift_received}
							redeemedAt={guest.gift_received_date}
							email={guest.email}
							pin={guest.pin}
							check_in_available={inputDate?.option ? true : false}
							loading={processingID === guest.id}
							deleteFromEvent={() => deleteGuestFromEvent(guest.id)}
							deleting={deletingGuest === guest.id ? true : false}
							checked_in={guest.check_in_times?.includes(inputDate.option) ? true : false}
							toggleCheckIn={(guestID, status ) => toggleUserCheckIn(guestID, status, false, guest.is_vcarb)}
							check_in_times={guest.check_in_times}
							tour_name={guest.tour_name}
							tour_start={guest.start_time}
							tour_guide={guest.guide}
							is_vcarb={guest.is_vcarb}
							editGuest={() => handleEditGuest(guest)}
						/>
					))}
				</>
			)}

			{viewingPin.pin && usePin && (
				<section className="thosp-viewing-checkin-pin">					
					{isVcarb ? (
						<img className="vcarb-logo" src={VCarbLogo} alt="Visa Cash App Racing Bulls" />
					) : (
						<img className="rbr-logo" src={RBRLogo} alt="Oracle Red Bull Racing" />
					)}

					<CosmosTitle appearance="light">Welcome to {country}!</CosmosTitle>
					<div className="thosp-event-location">
						<CountryFlag countryCode={countryCode} />

						<CosmosText
							appearance="light"
							size="x-small"
							spacing="none">
							{location}
						</CosmosText>
					</div>

					<br />

					<CosmosTitle
						appearance="light"
						className="thosp-pin"
						size="xx-large">
						{viewingPin.pin}
					</CosmosTitle>
					<CosmosText
						appearance="light"
						size="small"
						spacing="none"
						weight="bold">
						{viewingPin.name}
					</CosmosText>
					<CosmosText
						appearance="light"
						kind="subtle"
						size="x-small"
						spacing="none">
						{viewingPin.check_in_string}
					</CosmosText>

					<br />
					<br />

					<CosmosSelect
						className="thosp-tour-checkin-select"
						appearance="light"
						disabled={!tours || tours.length === 0}
						placeholder={tours.length > 0 ? "Join a tour" : "No tours available"}
						clearable={true}
						invalid={tourErrors && tourErrors.length > 0}
						validationMessage={tourErrors}
						onInputchange={({ detail }) => setJoinTourID(detail.value)}>
						{tours.map((tour) => (
							<CosmosOption value={tour.id}>
								{tour.name} ({tour.guest_count} of {tour.maximum_guests}) - {moment(tour.start_time).format("DD/MM HH:mm")}
							</CosmosOption>
						))}
					</CosmosSelect>

					<br />

					<CosmosButton
						size="small"
						onClick={() => processTourJoin({})}
						busy={joiningTour}>
						{joinTourID?.length > 0 ? "Join Tour & Return to check in" : "Return to check in"}
					</CosmosButton>
				</section>
			)}
		</>
	)
}