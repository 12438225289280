import { useEffect, useState, useRef } from 'react'
import './select.scss'

// UI components
import { CosmosIconCaretDown, CosmosText } from '@cosmos/web/react'

// Select dropdown
export default function RBRCosmosSelect(props) {
	const [showingDropdown, setShowingDropdown] = useState(false)

	// Close the dropdown list and return the value back up the props
	const optionSelected = (option) => {
		// Hide the dropdown
		setShowingDropdown(false)

		// Push the update up the props
		props.onSelect(option)
	}

	// Listen for a click outside the dropdown ref
	const ClickedOutside = (dropdownRef) => {
		useEffect(() => {
			// Check for the click to be outside the select field
			const checkForOutsideClick = (event) => {
				// Is the click outside?
				if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
					// Hide the dropdown
					setShowingDropdown(false)
				}
			}

			// Bind on the component load
			document.addEventListener('mousedown', checkForOutsideClick)

			return () => {
				// Unbind on component unload
				document.removeEventListener('mousedown', checkForOutsideClick)
			}
		}, [dropdownRef])
	}

	// Dropdown ref
	const dropdownRef = useRef(null)

	// Bind onto the click event
	ClickedOutside(dropdownRef)

	return (
		<div
			className={['rbr-cosmos-select-wrapper', props.className, props.error && 'has-error', props.readOnly && 'read-only', props.activeOnHover && 'only-active-on-hover'].join(' ')}
			ref={dropdownRef}
		>
			<div className="rbr-cosmos-input-upper-placeholder">
                <CosmosText appearance='light' spacing='none' size='x-small' weight='medium'>
                    {props.label}
                </CosmosText>
            </div>

			{/* Input section of the input */}
			<div
				className={['rbr-cosmos-select-upper no-styles', props.value && 'has-value', props.selected && 'has-value', showingDropdown && 'dropdown-showing'].join(' ')}
				onClick={() => setShowingDropdown((showingDropdown) => !showingDropdown)}
			>
				<div className="rbr-cosmos-nonsearch-select-wrap">
					{props.selected && <p className="rbr-cosmos-select-placeholder">{props.options[props.selected]}</p>}

					{!props.selected && <p className="rbr-cosmos-select-placeholder">
                        <CosmosText appearance='light' spacing='none' size='x-small' kind='subtle'>
                            {props.value || props.placeholder}
                        </CosmosText>
                    </p>}

					{/* Dropdown chevron  */}
					<div className="rbr-cosmos-select-icon">
						<CosmosIconCaretDown />
					</div>
				</div>
			</div>

			{/* Select dropdown, visible once clicked */}
			<div className={['rbr-cosmos-select-dropdown', showingDropdown && 'is-showing'].join(' ')}>
				{/* Print out all the options passed in */}
				{Object.entries(props.options).map(([option, value]) => (
					<div key={option} className="rbr-cosmos-select-option" onClick={() => optionSelected({ option, value })}>
						<CosmosText className='rbr-cosmos-select-option-text' appearance='light' spacing='none' size='small'>
                            {value}
                        </CosmosText>
					</div>
				))}
			</div>

			{/* Is there an error or note to display */}
			{props.error ? <small>{props.error}</small> : props.note && <small>{props.note}</small>}
		</div>
	)
}